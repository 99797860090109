import { formatValue } from 'react-currency-input-field';
import { Link } from 'react-router-dom';

export const JobAddress = ({job, includeFeedLink = false}) => {
  let jobLocation = '';

  if (job?.address) {
    jobLocation = `${job.address.city}, ${job.address.state.name} (${job.job_location_type.name})`;
  }
  else {
    jobLocation = `(${job?.job_location_type.name})`;
  }

  return (
    <>
      {includeFeedLink ?
        <span><Link to={`/employers/${job.business.id}/jobs`}>{job.business.name}</Link> - {jobLocation}</span>
      :
        <span>{job.business.name} - {jobLocation}</span>
      }
    </>
  );
};

export const JobSalary = (job) => {
  return `${formattedSalaryValue(job.minimum_salary, job.salary_rate)} - ${formattedSalaryValue(job.maximum_salary, job.salary_rate)} ${rate(job.salary_rate)}`;
};

const formattedSalaryValue = (salaryValue, salaryRate) => {
  salaryValue = `${ salaryRate === "per_hour" ?  parseFloat(salaryValue).toFixed(2) : parseInt(salaryValue) }`;
  return formatValue({value: String(salaryValue), groupSeparator: ',', decimalSeparator: '.', prefix: '$'});
};

const rate = (salary_rate) => {
  switch (salary_rate) {
    case 'per_hour':
      return 'an hour';
    case 'per_day':
      return 'a day';
    case 'per_week':
      return 'a week';
    case 'per_month':
      return 'a month';
    case 'per_year':
      return 'a year';
    default:
      break;
  };
};

export const rewardIcon = (reward, className = '') => {
  let iconClass;

  switch (reward.type) {
    case 'Reward::Cash':
      iconClass = 'fa-dollar-sign';
      break;
    case 'Reward::Merchandise':
      iconClass = 'fa-box-open';
      break;
    case 'Reward::GiftCard':
      iconClass = 'fa-gift';
      break;
    case 'Reward::Subscription':
      iconClass = 'fa-arrows-spin';
      break;
    case 'Reward::Experience':
      iconClass = 'fa-calendar-check';
      break;
    case 'Reward::EventTickets':
      iconClass = 'fa-ticket';
      break;
    case 'Reward::ProfessionalServices':
      iconClass = 'fa-keyboard';
      break;
    default:
      iconClass = 'fa-dollar-sign';
  }

  return <i className={`fa ${iconClass} ${className}`} />;
};

export const rewardLabel = (reward) => {
  switch (reward.type) {
    case 'Reward::Cash':
      return `$${parseInt(reward.amount)}`;
    case 'Reward::Merchandise':
      return 'Merchandise';
    case 'Reward::GiftCard':
      return `$${parseInt(reward.amount)} Gift Card`
    case 'Reward::Subscription':
      return 'Subscription';
    case 'Reward::Experience':
      return 'Experience';
    case 'Reward::EventTickets':
      return 'Event Tickets';
    case 'Reward::ProfessionalServices':
      return 'Services';
    default:
      return 'Unknown';
  }
};