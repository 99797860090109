import React, { useState } from 'react';
import { Alert, Modal, Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { signinUser, clearError } from '../../actions/authActions';
import { openModal, closeModal } from '../../actions/modalActions';
import { can } from '../../services/ability';

function LoginModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state) => state.authData);

  const [showPassowrd, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  function toggleShowPassword() {
    setShowPassword(!showPassowrd);
  };

  function handleHide(event) {
    if (authData.error)
    {
      dispatch(clearError());
    }
    dispatch(closeModal());
  }

  function handleSubmit(event) {
    setShowPassword(false);
    event.preventDefault();

    dispatch(signinUser(formData))
  };

  function handleForgotPasswordClick() {
    dispatch(openModal('ForgotPasswordModal', {}));
  };

  React.useEffect(() => {
    if (authData.loggedIn)
    {
      dispatch(closeModal());

      if(can(authData, 'read', 'employers_dashboard')) {
        navigate('/employers/dashboard');
      }
    }

    if (authData.error)
    {
      let errorMessage = authData.error.data.error;

      if(errorMessage !== undefined)
      {
        setErrorMessage(errorMessage);
      }
      else
      {
        setErrorMessage("An unknown error has occurred.");
      }
    }
  }, [authData, dispatch, navigate]);

  return (
    <div>
      <Modal show={true} onHide={e => handleHide(e)} fullscreen="md-down">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h5>Welcome back to Magnetworks</h5>
            <p>Log in</p>
          </div>

         {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}

          <Form onSubmit={e => handleSubmit(e)}>
            <Row>
              <Col xs={12}>
                <FloatingLabel controlId="email" label="Email" className="mb-3">
                  <Form.Control autoComplete="username" type="text" placeholder="Email" value={formData.email} onChange={e => setFormData({...formData, email: e.target.value})}/>
                </FloatingLabel>
              </Col>
              <Col xs={12} className="mb-3">
                <FloatingLabel controlId="password" label="Password">
                  <Form.Control autoComplete="current-password" type={showPassowrd ? "text" : "password"} placeholder="Password" value={formData.password} onChange={e => setFormData({...formData, password: e.target.value})}/>
                  <i className={showPassowrd ? "inline-icon icon-btn p-2 fa fa-eye-slash" : "inline-icon icon-btn p-2 fa fa-eye"} onClick={toggleShowPassword}/>
                </FloatingLabel>
              </Col>
              <Col xs={12} className="mb-3">
                <Button type="submit" className="standard-btn full-btn">Log in</Button>
              </Col>
              <Col xs={12} className="text-center mb-2">
                <Link to="#" onClick={handleForgotPasswordClick}>Forgot password?</Link>
              </Col>
              <hr className="w-75 m-auto" />
              <Col xs={12} className="text-center mt-2">
                <p className="mb-2">Need an account? <Link to="#" onClick={() => dispatch(openModal('SignUpModal'))}>Sign up</Link></p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default LoginModal;