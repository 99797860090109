import { useEffect, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { openModal } from '../../actions/modalActions';
import { can } from '../../services/ability';
import { useXLargeMediaQuery } from '../../hooks/responsiveMediaQueries';
import useGetApi from '../../hooks/useApiGet';
import ConditionalTooltip from '../ConditionalTooltip.js';

import orangeMagnetPath from '../../images/orange-magnet.png';
import paperPlanePath from '../../images/paper-airplane-step1.png';
import chatBubbleChartPath from '../../images/chat-bubble-chart-step2.png';
import bullseyePath from '../../images/bullseye-step3.png';

function Employers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useSelector((state) => state.authData);
  const isWithinXLarge = useXLargeMediaQuery();
  const membershipTerms = useGetApi({ url: '/api/v1/settings/new_membership_terms' }).data || [];

  function handleStartPostingClick() {
    if (authData.loggedIn && can(authData, 'create', 'Job')) {
      navigate('/employers/my_business/jobs/add');
    } else {
      dispatch(openModal('SignUpModal', {roleName: 'Employer'}));
    }
  }

  return(
    <>
      <Row className="g-0 employers-how-to-container">
        <Col md={{ span: 7, order: 1 }} xs={{ span: 12, order: 1 }} className={"ps-5 pe-5 pe-xl-0 light-background " + (isWithinXLarge ? "" : "left-half-abstract-magnet")}>

          <Row className="mt-4 pt-4">
            <Col xs={12} className="text-center text-md-start pb-2">
              <h1 className="display-5 fw-bold">Employers</h1>
            </Col>
          </Row>

          <Row>
            <Col xxl={8} xl={10} lg={12}>

              <Row className="mb-4">
                <Col xs={12}>
                  <p className="lead"><span className="fs-4 fw-bold tertiary-mw-text">Magnetworks</span> is a referral platform and job board that rallies friends and communities to find and attract your next hire.</p>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col xs={12} md={3} className="d-flex flex-column justify-content-center align-items-center pb-2 pb-md-0"><img alt="Paper airplane - step 1" className="paper-airplane-step1-img" src={paperPlanePath} /></Col>
                <Col xs={12} md={9} className="d-flex flex-column justify-content-center text-center text-md-start">
                  <div>
                    <span className="lead">Every tool you need to rally community and hire locally.</span><br />
                    <span className="small">Automated referral tracking, reward how you want, custom community referral program, simple & transparent pricing.</span>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col xs={12} md={3} className="d-flex flex-column justify-content-center align-items-center pb-2 pb-md-0"><img alt="Chat bubble with pi chart - step 2" className="chat-bubble-chart-step2-img" src={chatBubbleChartPath} /></Col>
                <Col xs={12} md={9} className="d-flex flex-column justify-content-center text-center text-md-start">
                  <div>
                    <span className="lead">Drive and track word of mouth by enlisting your community, customers, and supporters to share their referral links.</span>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col xs={12} md={3} className="d-flex flex-column justify-content-center align-items-center pb-2 pb-md-0"><img alt="Target with arrow - step 3" className="bullseye-step3-img" src={bullseyePath} /></Col>
                <Col xs={12} md={9} className="d-flex flex-column justify-content-center text-center text-md-start">
                  <div>
                    <span className="lead">Make a hire. Reward the referrer, not us.</span><br />
                    <span className="small"> Reward your referrer with your own products and services or a standard bonus</span>
                  </div>
                </Col>
              </Row>

              <Row className="text-center mt-3 mb-3">
                <div className="mt-2 lead">Bask in the glow of taking back control of your hiring.</div>
                <div className="mt-2 lead fw-bold tertiary-mw-text">Priceless.</div>
              </Row>

            </Col>
          </Row>

        </Col>

        <Col md={{ span: 5, order: 2 }} xs={{ span: 12, order: 2 }} className="d-flex flex-column vh-40-container vh-84-md-container right-half-abstract-magnet dark-background align-items-center justify-content-center">
          <ConditionalTooltip condition={(authData.loggedIn && !can(authData, 'create', 'Job'))} message={"You must be an employer to post jobs."}>
            <div className="d-inline-block">
              <Button className="px-1 light-btn cta-btn fw-bold mb-4" disabled={authData.loggedIn && !can(authData, 'create', 'Job') ? "disabled" : null} onClick={handleStartPostingClick}>Start Posting</Button>
            </div>
          </ConditionalTooltip>

          <Col xs={12} className="py-4 text-center">
            <h1 className="display-2 fw-bold" style={{ color: 'white' }}>Better <br />Jobs, <br />Found <br />Together</h1>
          </Col>

          <Col xs={12} className="text-center">
            <img alt="magnetworks" className="orange-magnet-img" src={orangeMagnetPath} />
          </Col>

        </Col>
      </Row>
    </>
  );
}

export default Employers;