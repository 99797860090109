import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { useDispatch } from 'react-redux';

import AutocompleteLocationInput from '../forms/AutocompleteLocationInput';
import JobsFiltersForFullPage from './JobsFiltersForFullPage';
import Mobile from '../responsive/Mobile';
import NonMobile from '../responsive/NonMobile';
import { openModal, closeModal } from '../../actions/modalActions';
import { useMobileMediaQuery } from "../../hooks/responsiveMediaQueries";
import "../../css/Jobs.css";

function JobsControls({ formDataContext, filterJobs }) {
  const dispatch = useDispatch();
  const formData = formDataContext.formData;
  const setFormData = formDataContext.setFormData;

  const [applyFilters, setApplyFilters] = useState(false);
  const [cookies, setCookie] = useCookies(["job_search"]);
  const isMobile = useMobileMediaQuery();

  function handleSubmit(event) {
    event.preventDefault();
    setCookie("job_search", JSON.stringify(formData), { path: "/" });
    filterJobs();
  };

  useEffect(() => {
    if (applyFilters) {
      filterJobs();
      setApplyFilters(false);
    }
  }, [applyFilters])

  useEffect(() => {
    if (!isMobile) {
      dispatch(closeModal());
    }
  }, [isMobile])

  function handleFilterClick() {
    dispatch(openModal('JobsFiltersModal', { formDataContext: formDataContext, setApplyFilters: setApplyFilters }));
  };

  return (
    <Row className="mt-4 mb-4 align-items-center">
      <Col xs={12}>
        <Form onSubmit={e => handleSubmit(e)}>
          <Row className="align-items-center">
            <Col md={5} sm={12}>
              <FloatingLabel controlId="what-job-search" label={["What? ", <span key="what-example" className="floating-label-sm">(e.g. Chef, Engineer, Teacher)</span>]} className="mb-3">
                <Form.Control key="1" className="form-control-sm" type="text" placeholder="What? (e.g. Chef, Engineer, Teacher)" value={formData.what_search_text} onChange={e => setFormData({...formData, what_search_text: e.target.value})}/>
                <i className="inline-icon p-2 fa fa-search"/>
              </FloatingLabel>
            </Col>
            <Col md={5} sm={12}>
              <AutocompleteLocationInput {...{ formData, setFormData }} />
            </Col>
            <Col md={2} xs={10} className="mb-3">
              <Button type="submit" className="standard-btn full-btn">Search</Button>
            </Col>
            <Mobile>
              <Col md={2} xs={2} className="mb-3">
                <Button className="standard-btn full-btn"><i className="fa fa-sliders" onClick={(e) => handleFilterClick()} /></Button>
              </Col>
            </Mobile>
          </Row>
          <NonMobile><JobsFiltersForFullPage {...{ formDataContext, setApplyFilters }} ></JobsFiltersForFullPage></NonMobile>
        </Form>
      </Col>
    </Row>
  );
}

export default JobsControls;