
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import GlobalAlert from '../components/GlobalAlert';

function RouteWithoutSidebar({ withContainer, ...props }) {
  function getGlobalAlert() {
    return props.useFixedTopHeader ? (<div className="fixed-top"><GlobalAlert /></div>) : <GlobalAlert />;
  }

  return (
    <>
      { withContainer ?
        <>
          <Header {...props} />
          <Container fluid>
            <Row>
              <Col>
                {getGlobalAlert()}
                <Row>
                  <Outlet />
                </Row>
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      :
        <>
          <Header {...props} />
          {getGlobalAlert()}
          <Outlet />
          <Footer />
        </>
      }
    </>
  );
}

export default RouteWithoutSidebar;