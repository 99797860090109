import React from 'react';
import Nav from 'react-bootstrap/Nav';
import '../css/Footer.css';

function Footer() {
  return(
    <div className="footer py-2 mt-auto">
      <Nav defaultActiveKey="/home">
        <span className="nav-link disabled">© {new Date().getFullYear()} Magnetworks, Inc.</span>
        <Nav.Link href="/about">How It Works</Nav.Link>
        <Nav.Link href="/employers">Employers</Nav.Link>
        <Nav.Link href="/terms">Terms</Nav.Link>
        <Nav.Link href="/privacy">Privacy</Nav.Link>
        <span className="nav-link disabled">Contact: support@magnetworks.co</span>
      </Nav>
    </div>
  );
}

export default Footer;