import React, { Suspense } from 'react';
import './App.css';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';

import ProtectedRoute from './layouts/ProtectedRoute';
import RouteWithoutSidebar from './layouts/RouteWithoutSidebar';

import Employers from './components/employers/Employers';
import ForgotPassword from './components/ForgotPassword';
import Home from './components/Home';
import HowItWorks from './components/HowItWorks.js';
import InstantApplyDefaults from './components/InstantApplyDefaults';
import Jobs from './components/jobs/Jobs';
import EmployersPublicLayout from './components/employers/public/EmployersPublicLayout.js';
import MyApplications from './components/MyApplications'
import MwApply from './components/MwApply'
import MyShareLinks from './components/MyShareLinks'
import NotFound from './components/NotFound.js';
import Privacy from './components/Privacy';
import ResetPassword from './components/ResetPassword';
import ShowJob from './components/ShowJob'
import SignUp from './components/SignUp';
import Terms from './components/Terms';
import UserSettings from './components/UserSettings';
import ViewShareLink from './components/ViewShareLink';
import AffiliateLinks from './components/affiliateLinks/AffiliateLinks';
import NewAffiliateLink from './components/affiliateLinks/NewAffiliateLink.js';
import EditAffiliateLink from './components/affiliateLinks/EditAffiliateLink.js';
import ViewAffiliateLink from './components/affiliateLinks/ViewAffiliateLink.js';
import AffiliateLinkDetails from './components/affiliateLinks/AffiliateLinkDetails.js';

const EmployersLayout = React.lazy(() => import('./components/employers/EmployersLayout'));
const EmployersDashboard = React.lazy(() => import('./components/employers/EmployersDashboard'));
const EmployersProfile = React.lazy(() => import('./components/employers/EmployersProfile'));
const EmployersJobs = React.lazy(() => import('./components/employers/EmployersJobs'));
const EmployersAddJob = React.lazy(() => import('./components/employers/EmployersAddJob'));
const EmployersJobApplications = React.lazy(() => import('./components/employers/EmployersJobApplications'));
const EmployersMembershipSignupWrapper = React.lazy(() => import('./components/employers/EmployersMembershipSignupWrapper'));

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-H07NPGYJKM');

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<RouteWithoutSidebar useFixedTopHeader={true} useLightButtons={true} />}>
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
      </Route>

      <Route element={<RouteWithoutSidebar/>}>
        <Route path='/about' element={<HowItWorks />} />
        <Route path='/employers' element={<Employers />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/employers/:business_id/jobs' element={<EmployersPublicLayout component={Jobs} />} />
        <Route path='/employers/:business_id/jobs/:job_id' element={<EmployersPublicLayout component={ShowJob} />} />
        <Route path='/employers/:business_id/jobs/:job_id/mwapply' element={<EmployersPublicLayout component={MwApply} />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path='/jobs/:job_id' element={<ShowJob />} />
        <Route path='/jobs/:job_id/mwapply' element={<ProtectedRoute action='manage' subject='basic'><MwApply /></ProtectedRoute>}/>
        <Route path='/s/:key' element={<ViewShareLink />}/>
        <Route path='/l/:key' element={<ViewAffiliateLink />}/>
        <Route path='/reset_password' element={<ResetPassword />} />
        <Route path='/forgot_password' element={<ForgotPassword />} />
        <Route path='/instant_apply_defaults' element={<ProtectedRoute action='create' subject='DefaultApplication'><InstantApplyDefaults /></ProtectedRoute>} />
        <Route path='/me' element={<ProtectedRoute action='manage' subject='basic'><UserSettings /></ProtectedRoute>} />

        <Route path='/admin'>
          <Route path='affiliate_links' element={<ProtectedRoute action='read' subject='affiliate_link'><AffiliateLinks /></ProtectedRoute>} />
          <Route path='affiliate_links/new' element={<ProtectedRoute action='create' subject='affiliate_link'><NewAffiliateLink /></ProtectedRoute>} />
          <Route path='affiliate_links/:id/edit' element={<ProtectedRoute action='update' subject='affiliate_link'><EditAffiliateLink /></ProtectedRoute>} />
          <Route path='affiliate_links/:id/details' element={<ProtectedRoute action='read' subject='affiliate_link'><AffiliateLinkDetails /></ProtectedRoute>} />
          <Route path='affiliate_links/:id/details' element={<AffiliateLinkDetails />} />
        </Route>
      </Route>

      <Route element={<RouteWithoutSidebar withContainer={true}/>}>
        <Route path='/my_applications' element={<ProtectedRoute action='read' subject='Application'><MyApplications /></ProtectedRoute>} />
        <Route path='/my_share_links' element={<ProtectedRoute action='manage' subject='basic'><MyShareLinks /></ProtectedRoute>} />
      </Route>

      <Route path='/employers'>
        <Route path='dashboard' element={<ProtectedRoute action='read' subject='employers_dashboard'><EmployersLayout component={EmployersDashboard} activeMenuKey='/employers/dashboard'/></ProtectedRoute>}/>
        <Route path='membership_signup' element={<ProtectedRoute action='create' subject='Membership'><EmployersLayout component={EmployersMembershipSignupWrapper} activeMenuKey='/employers/dashboard' /></ProtectedRoute>}/>
        <Route path='my_business/profile' element={<ProtectedRoute action='read' subject='Business'><EmployersLayout component={EmployersProfile} activeMenuKey='/employers/my_business/profile' /></ProtectedRoute>} />
        <Route path='my_business/jobs' element={<ProtectedRoute action='read' subject='employers_dashboard'><EmployersLayout component={EmployersJobs} activeMenuKey='/employers/my_business/jobs'/></ProtectedRoute>}/>
        <Route path='my_business/jobs/add' element={<ProtectedRoute action='create' subject='Job'><EmployersLayout component={EmployersAddJob} activeMenuKey='/employers/my_business/jobs'/></ProtectedRoute>}/>
        <Route path="my_business/jobs/:job_id/edit" element={<ProtectedRoute action="update" subject="Job"><EmployersLayout component={EmployersAddJob} activeMenuKey="/employers/my_business/jobs"></EmployersLayout></ProtectedRoute>}/>
        <Route path="my_business/jobs/:job_id/applications" element={<ProtectedRoute action="read" subject="Job"><EmployersLayout component={EmployersJobApplications} activeMenuKey="/employers/my_business/jobs"></EmployersLayout></ProtectedRoute>}/>
      </Route>

      <Route path="*" element={<NotFound/>} />
    </Route>
  )
);

function App() {
  return (
    <Suspense fallback={<></>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;